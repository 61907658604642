import React from "react"
import Layout from "../components/Layout"
import styles from "./index.module.scss"
import { graphql, Link } from "gatsby"
// import Img from "gatsby-image"
// import MyCard from "../components/MyCard"
import SEO from "../components/seo"
import HomeJSON from "../../content/PageDataHome.json"
import LayoutContainer from "../components/Common/LayoutContainer"

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulDestination {
      edges {
        node {
          description {
            childMarkdownRemark {
              excerpt
            }
          }
          slug
          title
          photo {
            fluid {
              src
            }
          }
        }
      }
    }
    file(relativePath: { eq: "main-image.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO title={data.site.siteMetadata.title} />
      <div className={styles.wrapper}>
        {/* Hero - TODO: 컴포넌트화 할 것 */}
        <section className={styles.heroSection}>
          <div className={styles.positioner}>
            {/* <div className={styles.contentWrapper}> */}
            <div className={"sectionContainer"}>
              <h1 className={styles.heroTitle}>
                {HomeJSON.title}
                <br />
              </h1>
              <p className={styles.heroDesc}>{HomeJSON.description}</p>
              <div className={styles.flexWrapper}>
                <Link to={HomeJSON.ctaButton.btnLink} className={styles.ctaBtn}>
                  {HomeJSON.ctaButton.btnName}
                </Link>
              </div>
            </div>
            {/* </div> */}
          </div>
        </section>

        <LayoutContainer>
          {data.allContentfulDestination.edges.map(({ node }, index) => {
            // const isOdd = index % 2 === 1
            return (
              <div key={node.slug} className={styles.bigTileArea}>
                <div className={styles.bigTileImgWrapper}>
                  <img
                    src={node.photo.fluid.src}
                    width="100%"
                    height="100%"
                    className={styles.bigTileImg}
                  />
                </div>
                <div className={styles.bigTileTextWrapper}>
                  <h1 className={styles.bigTileTitle}>{node.title}</h1>
                  <div
                    className={styles.bigTileText}
                    dangerouslySetInnerHTML={{
                      __html: node.description.childMarkdownRemark.excerpt,
                    }}
                  />
                  <div className={styles.bigTileBtn}>
                    <Link
                      to={`/dest/${node.slug}`}
                      className={styles.bigTileLink}
                    >
                      바로가기
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </LayoutContainer>

        {/* TODO: 여행지 Card List */}
        {/* <div className={styles.cardFeatured}>
          <p>Featured</p>
        </div>
        <section className={styles.cardSection}>
          {data.allContentfulDestination.edges.map(({ node }) => {
            return <MyCard item={node} key={node.slug} />
          })}
        </section> */}
      </div>
    </Layout>
  )
}
